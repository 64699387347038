










































































import Component from "vue-class-component";
import { Prop, Watch, Mixins } from "vue-property-decorator";

import {
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { Action, Getter, Mutation } from "vuex-class";

import {
  WorkflowActionPayload,
  WorkflowAction,
} from "@auditcloud/shared/lib/types/WorkflowActions";

import { api } from "@/store/modules/audit";
import { api as schemasApi, FormSchemaDynamic } from "@/store/modules/schemas";

import VueFormJsonSchema from "vue-form-json-schema";
import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";

import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { ActivityVerbs, getActivityCreator } from "@/utils/activityCreator";
import { AuditClassConfig } from "@auditcloud/shared/lib/schemas";

@Component({
  components: {
    VueFormJsonSchema,
  },
  mixins: [FormsControlMixin],
})
export default class AAuditPlanning extends Mixins(FormsControlMixin) {
  editable: boolean = false;
  loading: boolean = false;
  formValid: boolean = false;
  vfjsOptions: Object = {};

  @Prop({
    type: String,
    required: true,
  })
  readonly auditId!: string;

  @Getter(api.getters.getAuditFormData, { namespace: api.namespace })
  getAuditFormData!: TodoAny;
  @Getter(api.getters.getAuditFormSchema, { namespace: api.namespace })
  getAuditFormSchema!: (schemaId: string) => FormSchemaDynamic;

  @Getter(api.getters.getAuditPermissions, { namespace: api.namespace })
  auditPermissions!: AuditPermissions;

  @Getter(api.getters.getAuditRoles, { namespace: api.namespace })
  auditRoles!: string[];

  @Getter(api.getters.getAuditClass, { namespace: api.namespace })
  getAuditClass!: AuditClassConfig | null;

  @Getter(schemasApi.getters.getVfjsOptions, {
    namespace: schemasApi.namespace,
  })
  getVfjsOptions!: TodoAny;
  @Getter(schemasApi.getters.getVfjsCommonSchemas, {
    namespace: schemasApi.namespace,
  })
  getVfjsCommonSchemas!: TodoAny;

  @Getter(api.getters.getActionsPerView, { namespace: api.namespace })
  actionsPerView!: (viewname: string) => string[];
  get readonly() {
    return !this.auditPermissions.writeMetadata;
  }

  get next() {
    return {
      name: "preparation",
      params: {
        auditId: this.auditId,
      },
    };
  }

  get prev() {
    return "";
  }

  get auditFormData() {
    return this.getAuditFormData(this.formSchemaId);
  }
  @Watch("auditFormData", { immediate: true, deep: true })
  onAuditFormDataChanged(newVal: any, oldVal: any) {
    this.mixinInitFormData(newVal);
    this.vfjsOptions = this.getVfjsOptions;
  }

  mounted() {
    this.$vuetify.goTo(0);

    const actionMap: { [id: string]: WorkflowAction } = {};
    const actionsList = this.actionsPerView("planning")
      .filter(id => id in actionMap)
      .map(id => actionMap[id]);

    const primary = actionsList.length > 0 ? { primary: actionsList[0] } : {};
    const secondary =
      actionsList.length > 1
        ? {
            secondary: actionsList.slice(1),
          }
        : {};
    const actions: WorkflowActionPayload = { ...primary, ...secondary };

    this.$emit("actions", actions);
  }

  get formSchemaId() {
    if (this.dynamicschema) {
      return this.dynamicschema;
    } else {
      return "metadata-form";
    }
  }
  get dynamicschema() {
    if (this.$route.query.schema) {
      return Array.isArray(this.$route.query.schema)
        ? this.$route.query.schema[0] ?? ""
        : this.$route.query.schema ?? "";
    }

    return "";
  }

  get formSchema() {
    const res = this.getAuditFormSchema(this.formSchemaId);

    return res;
  }
  get uiSchema() {
    return this.formSchema.ui(!this.editable || this.readonly, this.readonly);
  }
  get schema() {
    const res = this.formSchema.data;

    return res;
  }

  @Action(api.actions.updateAuditMetadata, { namespace: api.namespace })
  updateAuditMetadata!: TodoActionSignature;

  @Mutation(api.mutations.SET_AUDIT_STATE_DIRTY, { namespace: api.namespace })
  setAuditStateDirty!: (isDirty: boolean) => void;

  editControl() {
    // TODO: Macht es sinn hier zu klonen und nicht schon beim created hook?
    this.setAuditStateDirty(true);
    this.editable = true;
    this.mixinInitFormData(this.auditFormData);
  }
  discardControl() {
    if (this.confirmCancel()) {
      this.setAuditStateDirty(false);

      this.mixinInitFormData(this.auditFormData);
      this.editable = false;
    }
  }
  saveChanges() {
    if (this.formValid) {
      const activityCreator = getActivityCreator(
        this.$user,
        this.getAuditClass,
        {
          ...this.mixinFormData,
        }
      );

      this.mixinFormData.responsible = this.mixinFormData.responsible || null;

      console.assert(
        typeof this.auditId === "string",
        "expect valid auditId got",
        this.auditId,
        this
      );
      if (typeof this.auditId === "string") {
        this.editable = false;
        this.updateAuditMetadata({
          activity: activityCreator?.(this.auditId, ActivityVerbs.UPDATE),
          id: this.auditId,
          data: this.mixinFormData,
        }).then(() => {
          console.log("UPDATE_AUDIT_SUCCEEDED");
          this.loading = false;
          this.setAuditStateDirty(false);
        });
        this.mixinInitFormData(this.mixinFormData);
      }
    } else {
      this.vfjsOptions = {
        ...this.getVfjsOptions,
        showValidationErrors: true,
      };

      console.log("Form invalid");
    }
  }
  change(e) {
    console.log('"change" event', e);
    this.mixinFormData = e;
  }
  onValidated(isValid) {
    console.log('"validated" event', isValid);
    this.formValid = isValid;
  }
  onChangeState(e) {
    console.log('"change-state" event', e);
  }
  resetData() {
    // TODO: Daten werden zwar zurückgesetzt, aber danach funktioniert das dynamische Form nicht mehr
  }
}
